import React, { Fragment, useEffect, useState } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

//** Import Image */
import profile from "../../../../images/avatar/1.jpg";
import PageTitle from "../../../layouts/PageTitle";
import { Button, Modal } from "react-bootstrap";
import { Logout } from "../../../../store/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import AdminService from "../../../../services/AdminService";
import Swal from "sweetalert2";
import { saveTokenInLocalStorage } from "../../../../services/AuthService";
import Loader from "../../Loader";

const AppProfile = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [logo, setLogo] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [serviceFee, setServiceFee] = useState("");
  const [safety_deposit_fee, set_safety_deposit_fee] = useState("");

  let errorsObj = {
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    profile_image: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        profile_image: imageUrl,
      }));
      setLogo(file);
    }
  };

  const handleFeeChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*\.?\d*$/.test(value) && value.length <= 6) {
      setServiceFee(value);
    }
  }

  const handleFeeSave = () => {
    console.log("-=-=-=serviceFee= ", serviceFee);
    const passData = {
      service_fee: serviceFee
    }
    AdminService.UpdateConfig(passData)
      .then((resp) => {
        console.log("resp =  ", resp);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Service fee updated successfully!",
          }).finally(function () {
            // fetchConfigs();
          })
        }
      })
      .catch((err) => {
        console.log("err = ", err)
      })
  }

  const fetchConfigs = () => {
    AdminService.GetConfig()
      .then((resp) => {
        console.log("GetConfig resp = ", resp);
        if (resp.success) {
          const serviceFeeConfig = resp.data.find(item => item.key === "service_fee");
          if (serviceFeeConfig) {
            setServiceFee(serviceFeeConfig.value);
          }

          const depositeFee = resp.data.find(item => item.key === "safety_deposit_fee");
          if (depositeFee) {
            set_safety_deposit_fee(depositeFee.value);
          }
        }
      })
      .catch((err) => {
        console.log("GetConfig err = ", err)
      })
  }

  useEffect(() => {
    setLoader(true);
    fetchConfigs();
    AdminService.GetProfile()
      .then((response) => {
        if (response.success) {
          const { first_name, last_name, email, address, profile_image } =
            response.data;
          setFormData({
            first_name: first_name ?? "",
            last_name: last_name ?? "",
            email: email ?? "",
            address: address ?? "",
            profile_image: profile_image ?? "",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 403 || error?.response?.status == 401) {
          Logout(navigate);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace null values with empty strings
    const sanitizedFormData = Object.fromEntries(
      Object.entries(formData)
    );
    if (logo != "") {
      sanitizedFormData.image = logo;
    }

    // Filter out entries with null or empty string values
    const filteredFormData = Object.fromEntries(
      Object.entries(sanitizedFormData).filter(
        ([key, value]) =>
          key !== "profile_image"
      )
    );
    setLoader(true);
    AdminService.UpdateProfile(filteredFormData)
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Details updated successfully!",
          }).finally(function () {
            const userDetails = JSON.parse(localStorage.getItem("userDetails"));
            if (resp.detail.first_name) {
              userDetails.first_name = resp.detail.first_name;
            }
            if (resp.detail.last_name) {
              userDetails.last_name = resp.detail.last_name;
            }
            if (resp.detail.profile_image) {
              userDetails.profile_image = resp.detail.profile_image;
            }
            saveTokenInLocalStorage(userDetails);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status == 403 || error?.response?.status == 401) {
          Logout(navigate);
        }
        if (error?.response?.data?.message?.length > 0) {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: error?.response?.data?.message,
          });
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleSubmitPasswordChange = (e) => {
    e.preventDefault();
    let error = true;
    const errorObj = { ...errorsObj };
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!newPassword.trim()) {
      errorObj.newPassword = "New Password is required";
      error = false;
    } else if (!passwordRegex.test(newPassword)) {
      errorObj.newPassword =
        "New Password must be at least 8 characters long with one uppercase and one lowercase letter";
      error = false;
    }
    if (!confirmPassword.trim()) {
      errorObj.confirmPassword = "Confirm Password is required";
      error = false;
    } else if (confirmPassword !== newPassword) {
      errorObj.confirmPassword = "Confirm Password is not same as password.";
      error = false;
    }
    setErrors(errorObj);
    if (!error) {
      return;
    }
    const passData = {
      password: newPassword,
      confirm_password: confirmPassword,
    };
    setLoader(true);
    AdminService.UpdatePassword(passData)
      .then((resp) => {
        console.log("response = ", resp);
        if (resp.success) {
          setOpenPasswordModal(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Password has been changed successfully.",
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleDepositSubmit = () => {
    AdminService.UpdateDepositFee({ safety_deposit_fee })
      .then((resp) => {
        if (resp.success) {
          console.log("Updated successfully")
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      })
  }

  return loader ? <Loader /> : (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              {/* <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div> */}
              <div className="profile-info">
                <div className="profile-photo" style={{ marginTop: "unset" }}>
                  <img
                    src={
                      formData.profile_image !== ""
                        ? formData.profile_image
                        : profile
                    }
                    alt="profile"
                    style={{
                      height: "80px",
                      width: "80px", // Ensure the width is equal to the height
                      borderRadius: "50%" // Make the image circular
                    }}
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {formData.first_name + " " + formData.last_name}
                    </h4>
                    <p>Admin</p>
                  </div>
                  {/* <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{formData.email}</h4>
                    <p>Email</p>
                  </div> */}
                </div>
                <div>
                  <Button
                    className="btn btn-primary rounded light"
                    onClick={() => {
                      setOpenPasswordModal(true);
                      setNewPassword("");
                      setConfirmPassword("");
                    }}
                  >
                    Password
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="settings-form">
                    <h4 className="text-primary">Account Setting</h4>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">First Name</label>
                          <input
                            placeholder="Enter first name"
                            className="form-control"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Last Name</label>
                          <input
                            placeholder="Enter last name"
                            className="form-control"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Email</label>
                          <input
                            placeholder="Enter email"
                            className="form-control"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Logo</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">Address</label>
                        <input
                          type="text"
                          placeholder="Enter complete address"
                          className="form-control"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label className="form-label">
                              Latitude (Coordinates)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Latitude for map location"
                            />
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="form-label">
                              Longitude (Coordinates)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Longitude for map location"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">City</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter city"
                            />
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">State</label>
                            <select
                              className="form-control"
                              id="inputState"
                              defaultValue="option-1"
                            >
                              <option value="option-1">Choose...</option>
                              <option value="option-2">Option 1</option>
                              <option value="option-3">Option 2</option>
                              <option value="option-4">Option 3</option>
                            </select>
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Zip</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter zip code"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Facebook</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your facebook link"
                            />
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Instagram</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your instagram link"
                            />
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Website</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your website"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Telephone 1</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your phone number"
                            />
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Telephone 2</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your phone number"
                            />
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">
                              Distance from appart (KM/min)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter distance from property"
                            />
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label className="form-label">Bank account</label>
                          <input
                            type="text"
                            placeholder="XXXX XXXX XXXX"
                            className="form-control"
                          />
                        </div>

                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Comment 1</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter comment"
                            />
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Comment 2</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter comment"
                            />
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Comment 3</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter comment"
                            />
                          </div>
                        </div> */}
                      <div className="form-group mb-3"></div>
                      <button className="btn btn-primary" type="submit">
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="settings-form">
                    <h4 className="text-primary">Fees Setting</h4>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="form-label">Service Fee</label>
                        <div className="d-flex">
                          <div className="input-group me-4 w-25">
                            <input
                              type="text"
                              className="form-control"
                              value={serviceFee}
                              onChange={handleFeeChange}
                            />
                            <span className="input-group-text">%</span>
                          </div>
                          <button disabled={serviceFee.length > 0 ? false : true} className="btn btn-primary" onClick={handleFeeSave}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="settings-form">
                    <h4 className="text-primary">Safety Deposit Setting</h4>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="form-label">Admin Fee</label>
                        <div className="d-flex">
                          <div className="input-group me-4 w-25">
                            <input
                              type="text"
                              className="form-control"
                              value={safety_deposit_fee}
                              onChange={(e) => set_safety_deposit_fee(e.target.value)}
                            />
                            <span className="input-group-text">%</span>
                          </div>
                          <button disabled={safety_deposit_fee.length > 0 ? false : true} className="btn btn-primary" onClick={handleDepositSubmit}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="fade"
        show={openPasswordModal}
        onHide={() => setOpenPasswordModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Change Password</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setOpenPasswordModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={() => { }}>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">New Password</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setErrors({
                        ...errors,
                        newPassword: "",
                      });
                    }}
                  />
                  {errors.newPassword && (
                    <div className="text-danger fs-12">
                      {errors.newPassword}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Confirm Password</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setErrors({
                        ...errors,
                        confirmPassword: "",
                      });
                    }}
                  />
                  {errors.confirmPassword && (
                    <div className="text-danger fs-12">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenPasswordModal(false)} variant="primary">
            {" "}
            No{" "}
          </Button>
          <Button onClick={handleSubmitPasswordChange}> Save </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AppProfile;
