import React from "react";

const Footer = () => {
  let dat = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          <a href="https://www.snapinapp.com/privacy-policy" target="_blank"  rel="noreferrer">
            Privacy Policy |
          </a>{" "}
          <a href="https://www.snapinapp.com/terms-of-use" target="_blank"  rel="noreferrer">
            Terms of use |
          </a>{" "}
          <a href="https://www.snapinapp.com/legal-disclaimer" target="_blank"  rel="noreferrer">
            Legal Disclaimer |
          </a>{" "}
          <a href="https://www.snapinapp.com/cookies-policy" target="_blank"  rel="noreferrer">
            Cookies Policy
          </a>{" "}
          © {dat.getFullYear()} SnapInApp. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
