import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminService from "../../../services/AdminService";
import Swal from "sweetalert2";
import Loader from "../Loader";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from "./StrictModeDroppable";

const MainService = () => {
  const [loader, setLoader] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmModalOpen, setConfirmModal] = useState(false);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(serviceList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Re-order list
    items.map((item, index) => item.order = index)

    reorderMainServices(items);
  };

  const [serviceList, setServiceList] = useState([]);
  const [items, setItems] = useState([]);
  const [service, setService] = useState("");
  const [image, setImage] = useState(null);
  const [icon, setIcon] = useState(null);
  const [id, setId] = useState("");

  const errorsObj = {
    service: "",
    image: "",
    icon: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleAddMainService = (e) => {
    e.preventDefault();
    let error = true;
    const errorObj = { ...errorsObj };
    if (!service.trim()) {
      errorObj.service = "Name is required";
      error = false;
    }
    if (image == null) {
      errorObj.image = "Image is required";
      error = false;
    }
    if (icon == null) {
      errorObj.icon = "Icon is required";
      error = false;
    }
    setErrors(errorObj);
    if (!error) {
      return;
    }
    const passData = {
      service,
      image,
      icon,
    };
    AdminService.AddMainService(passData)
      .then((resp) => {
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Main service has been added successfully.",
          }).finally(function () {
            setShowAddModal(false);
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log("error in add service = ", error);
      });
  };
  const handleEditMainService = (e) => {
    e.preventDefault();
    let error = true;
    const errorObj = { ...errorsObj };
    const finalData = {};
    if (!service.trim()) {
      errorObj.service = "Name is required";
      error = false;
    }
    if (image != null) {
      finalData.image = image;
    }
    if (icon != null) {
      finalData.icon = icon;
    }
    finalData.service = service;
    setErrors(errorObj);
    if (!error) {
      return;
    }
    console.log("finalData  = ", finalData);
    AdminService.UpdateMainService(finalData, id)
      .then((resp) => {
        if (resp.success) {
          setShowEditModal(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Main has been updated successfully.",
          }).finally(function () {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log("error in add service = ", error);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setErrors({
        ...errors,
        image: "",
      });
    }
  };
  const handleIconChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIcon(file);
      setErrors({
        ...errors,
        icon: "",
      });
    }
  };

  const handleDeleteMainService = () => {
    console.log("=-==-", id);

    AdminService.DeleteMainService(id)
      .then((resp) => {
        if (resp.success) {
          setConfirmModal(false)
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Main has been deleted successfully.",
          }).finally(function () {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log("error in delete service = ", error);
      });
  };

  const reorderMainServices = (passData) => {
    setLoader(true);
    AdminService.ReorderMainServices(passData)
      .then((resp) => {
        if (resp.success) {
          handleFetchServicesList();
        }
      })
      .catch((error) => {
        console.log("error=-=-=-=-=-=-=-=-=-=-=-", error);
      })
      .finally(function () {
        setLoader(false);
      });
  }

  const handleFetchServicesList = () => {
    AdminService.GetMainServices()
      .then((resp) => {
        if (resp.success) {
          setServiceList(resp?.data?.list);
        }
      })
      .catch((error) => {
        console.log("error=-=-=-=-=-=-=-=-=-=-=-", error);
      })
      .finally(function () {
        setLoader(false);
      });
  }

  useEffect(() => {
    setLoader(true);
    handleFetchServicesList();
  }, []);

  return loader ? <Loader /> : (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Main Services</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/main-services">Main Services List</Link>
            </li>
          </ol>
        </div>
        <Button
          to="/main-services"
          className="btn btn-primary rounded light me-3"
          onClick={() => {
            setShowAddModal(true);
            setService("");
            setImage(null);
            setIcon(null);
          }}
        >
          Add Main Service
        </Button>
      </div>
      <Tab.Container defaultActiveKey={"List"}>
        <Tab.Content>
          <Tab.Pane eventKey={"List"}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <StrictModeDroppable droppableId="services" direction="horizontal">
              {/* <Droppable droppableId="services" direction="horizontal"> */}
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="row"
                  >
                    {serviceList.map((item, ind) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={ind}>
                        {(provided) => (
                          <div
                            className="col-xl-3 col-xxl-3 col-md-4 col-sm-4 col-lg-4 m-b30"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="property-card style-1 ">
                              <Link to={`/sub-services/${btoa(item.service)}/${btoa(item.id)}`}>
                                <div className="dz-media">
                                  <img
                                    className="fix-height-250"
                                    src={item.image}
                                    alt="/"
                                  />
                                </div>
                              </Link>
                              <div className="dz-content" style={{ paddingBottom: "5px" }}>
                                <div className="dz-footer">
                                  <div className="dz-media d-flex align-items-center">
                                    <img
                                      className="fix-height-30"
                                      src={item?.icon}
                                      alt="icon"
                                    />
                                    <h5 className="title mb-0" style={{ marginLeft: "10px" }}>
                                      {item.service}
                                    </h5>
                                  </div>
                                  <Button
                                    onClick={() => {
                                      setService(item.service);
                                      setId(item.id);
                                      setShowEditModal(true);
                                    }}
                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                  >
                                    <i className="fa fa-pencil fa-lg"></i>
                                  </Button>
                                </div>
                                <Link to={`/sub-services/${btoa(item.service)}/${btoa(item.id)}`}>
                                  <div className="dz-meta mt-2">
                                    <span className="badge badge-primary">
                                      {item.total_sub_services} services
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              {/* </Droppable> */}
              </StrictModeDroppable>
            </DragDropContext>
          </Tab.Pane>
        </Tab.Content >
      </Tab.Container >

      {/* Add Service Modal */}
      < Modal
        className="fade"
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Add Main Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setShowAddModal(false);
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-4">
                <label className="form-label">Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="service"
                    value={service}
                    onChange={(e) => {
                      setService(e.target.value);
                      setErrors({
                        ...errors,
                        service: "",
                      });
                    }}
                  />
                  {errors.service && (
                    <div className="text-danger fs-12">{errors.service}</div>
                  )}
                </div>
              </div>
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Image</label>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={handleFileChange}
                  />
                  {errors.image && (
                    <div className="text-danger fs-12">{errors.image}</div>
                  )}
                </div>
              </div>
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Icon</label>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    name="icon"
                    onChange={handleIconChange}
                  />
                  {errors.icon && (
                    <div className="text-danger fs-12">{errors.icon}</div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowAddModal(false)} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={handleAddMainService} variant="success"> Add </Button>
        </Modal.Footer>
      </Modal >

      {/* Edit Service Modal */}
      < Modal
        className="fade"
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>Edit Main Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowEditModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="mb-12 col-lg-12 col-md-12 mb-4">
                <label className="form-label">Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="service"
                    value={service}
                    onChange={(e) => {
                      setService(e.target.value);
                      setErrors({
                        ...errors,
                        service: "",
                      });
                    }}
                  />
                  {errors.service && (
                    <div className="text-danger fs-12">{errors.service}</div>
                  )}
                </div>
              </div>
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Icon</label>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    name="icon"
                    onChange={handleIconChange}
                  />
                </div>
              </div>
              <div className="mb-12 col-lg-12 col-md-12 mb-2">
                <label className="form-label">Image</label>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowEditModal(false)} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={handleEditMainService} variant="success"> Save </Button>
        </Modal.Footer>
      </Modal >

      {/* delete service modal */}
      < Modal className="fade" show={confirmModalOpen} centered >
        <Modal.Header>
          <Modal.Title>Main Service</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setConfirmModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure want to delete this service ? </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setConfirmModal(false)} variant="danger">
            {" "}
            Close{" "}
          </Button>
          <Button onClick={() => handleDeleteMainService()} variant="success"> Yes </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default MainService;
