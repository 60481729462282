import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { Link } from "react-router-dom";
import profile from "../../../images/avatar/1.jpg";
import { Button, Dropdown, Modal } from "react-bootstrap";
import AdminService from "../../../services/AdminService";
import moment from 'moment';
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import { ColumnFilter } from '../FilteringTable/ColumnFilter';
import '../FilteringTable/filtering.css';
import { format } from 'date-fns';
import { Cell } from "recharts";
import BookingDetails from "../BookingDetails"

function GuestInformation() {
  const COLUMNS = [
    {
      Header: 'ID',
      Footer: 'ID',
      accessor: 'id',
      Filter: ColumnFilter,
      //disableFilters: true,
    }, 
    {
      Header: 'Booking ID',
      Footer: 'Booking ID',
      accessor: 'booking_id',
      Filter: ColumnFilter,
    },
    {
      Header: 'Booking Name',
      Footer: 'Booking Name',
      accessor: 'booking_name',
      Filter: ColumnFilter,
    },
    {
      Header: 'Property',
      Footer: 'Property',
      accessor: 'property_name',
      Filter: ColumnFilter,
    },
    {
      Header: 'Guest Name',
      Footer: 'Guest Name',
      accessor: 'guest_name',
      Filter: ColumnFilter,
    },
    {
      Header: 'Email',
      Footer: 'Email',
      accessor: 'guest_email',
      Filter: ColumnFilter,
    },
    {
      Header: 'Phone',
      Footer: 'Phone',
      accessor: 'guest_phone',
      Filter: ColumnFilter,
    },
    {
      Header: 'Nationality',
      Footer: 'Nationality',
      accessor: 'guest_nationality',
      Filter: ColumnFilter,
    },
    {
      Header: 'DOB',
      Footer: 'DOB',
      accessor: 'guest_dob',
      Filter: ColumnFilter,
    },
    {
      Header: 'Birth Place',
      Footer: 'Birth Place',
      accessor: 'guest_birth_place',
      Filter: ColumnFilter,
    },
    {
       Header: 'ID Card Number',
       Footer: 'ID Card Number',
       accessor: 'guest_ID_card_number',
       Filter: ColumnFilter,
    },
    {
      Header: 'ID Card',
      Footer: 'ID Card',
      accessor: 'guest_ID_card_photo',
      disableFilters: true,
      Cell: ({ value }) => {
        return <>
          {value != null &&
            <Link to={`${value}`} target="_blank">
              <img className="fix-height-50 " src={value} alt="ID Card" />
            </Link>
          }
        </>
      },
    },
    {
      Header: 'Address',
      Footer: 'Address',
      accessor: 'guest_address',
      Filter: ColumnFilter,
    },
    {
      Header: 'City',
      Footer: 'City',
      accessor: 'guest_city',
      Filter: ColumnFilter,
    },
    {
      Header: 'Postcode',
      Footer: 'Postcode',
      accessor: 'guest_postcode',
      Filter: ColumnFilter,
    },
    {
      Header: 'Country',
      Footer: 'Country',
      accessor: 'guest_country',
      Filter: ColumnFilter,
    },
    {
      Header: 'Date',
      Footer: 'Date',
      accessor: 'created_at',
      Filter: ColumnFilter,
      Cell: ({ value }) => {return format(new Date(value), 'dd-MM-yyyy, hh:mm a')},
    },
  ]

  const [loader, setLoader] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderListLoader, setOrderListLoader] = useState(false);
  const [statusOption, setStatusOption] = useState("all");
  const [orderStatusValue, setOrderStatusValue] = useState(null);
  const [bookingIDs, setBookingIDs] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [activeTab, setActiveTab] = useState("guestInfo");

  const orderStatus = [
    {
      label: "Created",
      value: "created"
    },
    {
      label: "Accepted",
      value: "accepted"
    },
    {
      label: "Rejected",
      value: "rejected"
    },
    {
      label: "Claimed",
      value: "claimed"
    },
    {
      label: "Refund",
      value: "refunded"
    },
    {
      label: "Completed",
      value: "completed"
    },
  ]

  const fetchGuestInfo = (booking_id) => {
   
    AdminService.getGuestInfo(booking_id)
      .then((resp) => {
        console.log("resp = ", resp);
        if (resp.success) {
          setOrderList(resp.data);
        }
      })
      .catch((err) => {
        console.log("err in order list = ", err);
      })
      .finally(function () {
        setLoader(false);
        setOrderListLoader(false);
      })
  }
  const handleFilter = (e) => {
    setOrderList([]);
    setOrderListLoader(true);
    console.log("-=-=-", e.target.value);
    setStatusOption(e.target.value);
    fetchGuestInfo(e.target.value);
  }

  useEffect(() => {
    setLoader(true);
    AdminService.getBookings()
      .then((resp) => {
        console.log("fetch bookings resp = ", resp);
        if (resp.success) {
          setBookingIDs(resp.data);
        }
      })
      .catch((err) => {
        console.log("err = ", err);
      })
    fetchGuestInfo('all');
  }, []);

  const columns = useMemo(() => COLUMNS, [])
  const data = useMemo(() => orderList, [orderList])
  const tableInstance = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0
    }
  }, useFilters, useGlobalFilter, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance


  const {
    globalFilter,
    pageIndex
  } = state
  return loader ? <Loader /> : (
    <>
      <div className="form-head page-titles d-flex  align-items-center">
        <div className="me-auto  d-lg-block">
          <h4 className="mb-1">Guest Information</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/guest-information">Guest Information List</Link>
            </li>
          </ol>
        </div>
         <label>Booking ID &nbsp;&nbsp;&nbsp;&nbsp;</label>
        <select
          name="booking_ids"
          className="form-select form-control-lg rounded"
          value={statusOption}
          style={{
            cursor: "pointer",
            width: "fit-content",
            fontSize: "14px",
            backgroundColor: "var(--primary)",
            color: "white",
            marginRight: "10px"
          }}
          onChange={(e) => handleFilter(e)}
        >
          <option value="all">All</option>
          {bookingIDs.length > 0 &&
            bookingIDs.map((item, index) => (
              <option key={index} value={item.id}>{item.booking_id}</option>
            ))}
        </select>
      </div>

      {/* Tab View */}
      <div className="tab-view">
        <div className="tab-headers d-flex align-items-center mb-3">
          <button 
            className={`tab-button ${activeTab === 'guestInfo' ? 'active' : ''}`}
            onClick={() => setActiveTab("guestInfo")}
            style={{ marginRight: "10px" }}
          >
            Guest Info
          </button>
          <button 
            className={`tab-button ${activeTab === 'bookingDetails' ? 'active' : ''}`}
            onClick={() => setActiveTab("bookingDetails")}
          >
            Booking Details
          </button>
        </div>
      </div>

      <div className="row">
        {activeTab === "guestInfo" && (
          <div className="col-xl-12">
              <div className="card">
                  <div className="card-body">
                      <div className="table-responsive table-hover fs-14 order-list-table">
                          {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                          <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                            <thead>
                              {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                  {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                      {column.render('Header')}
                                      {column.canFilter ? column.render('Filter') : null}
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead> 
                            <tbody {...getTableBodyProps()} className="" >
                            
                              {page.map((row) => {
                                prepareRow(row)
                                return(
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                      return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                                    })}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                          <div className="d-flex justify-content-between">
                            <span>
                              Page{' '}
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>{''}
                            </span>
                            <span className="table-index">
                              Go to page : {' '}
                              <input type="number" 
                                className="ms-2"
                                defaultValue={pageIndex + 1} 
                                onChange = {e => { 
                                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                                  gotoPage(pageNumber)
                                } } 
                              />
                            </span>
                          </div>
                          <div className="text-center">	
                            <div className="filter-pagination  mt-3">
                              <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                              
                              <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                Previous
                              </button>
                              <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                Next
                              </button>
                              <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                            </div>
                          </div>
                      </div>
                      {/* <CustomPagination totalPages={10} currentPage={currentPage} onPageChange={(newpage) => { setCurrentPage(newpage) }} /> */}
                  </div>
              </div>
          </div>
        )}

        {activeTab === "bookingDetails" && (
          <BookingDetails />
        )}
      </div >
    </>
  );
}

export default GuestInformation;
